<template>
  <div class="form-item-wrap" :class="{ 'form-item-wrap-focus': isFocus }">
    <div class="label">{{ label }}<span v-if="required" class="required">*</span></div>
    <div class="my-field">
      <Field
        v-model="fieldValue"
        :placeholder="placeholder"
        :type="type"
        :maxlength="maxlength"
        @focus="focusHandle"
        @blur="blurHandle"
        @update:model-value="updateHandle"
        :readonly="readonly"
        @click="fieldClickHandle"
      />
      <slot></slot>
    </div>
  </div>
  <Popup v-model:show="state.showPicker" round position="bottom">
    <Picker
      :title="title"
      :columns="columns"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </Popup>
</template>

<script>
import { reactive, ref, watchEffect } from 'vue'
import { Field, Picker, Popup } from 'vant'
export default {
  components: {
    Field,
    Picker,
    Popup
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    maxlength: {
      type: [Number, String]
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '标题'
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'on-change', 'on-focus', 'on-blur'],
  setup (props, { emit }) {
    const state = reactive({
      showPicker: false
    })
    const isFocus = ref(false)
    const focusHandle = () => {
      isFocus.value = true
      emit('on-focus')
    }

    const fieldValue = ref(props.modelValue)
    watchEffect(() => {
      fieldValue.value = props.modelValue
    })

    const blurHandle = () => {
      isFocus.value = false
      emit('on-blur')
    }

    const updateHandle = (value) => {
      emit('update:modelValue', value)
      emit('on-change', value)
    }

    const onConfirm = (value) => {
      state.showPicker = false
      emit('update:modelValue', value)
    }

    const onCancel = () => {
      state.showPicker = false
    }

    const fieldClickHandle = () => {
      if (props.readonly) {
        state.showPicker = true
      }
    }

    return {
      state,
      isFocus,
      focusHandle,
      blurHandle,
      updateHandle,
      fieldValue,
      onConfirm,
      onCancel,
      fieldClickHandle
    }
  }
}
</script>

<style lang="less" scoped>
.form-item-wrap {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #B2BBD7;
    transform: scaleY(.5);
  }
  .label {
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    .required {
      color: #FF2C2C;
    }
  }
  .my-field {
    display: flex;
    align-items: center;
    margin-top: 10px;
    &:deep(.van-cell) {
      padding: 0;
      height: 30px;
      line-height: 30px;
    }
    &:deep(.van-field__value) {
      padding-left: 8px;
    }
  }
}
.form-item-wrap-focus {
  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #007FFF;
    transform: scaleY(.5);
  }
  .my-field {
    &:deep(input) {
      color: #007FFF;
    }
  }
}
</style>
